import { Cursor } from '@grid-ui/common';
import { DEFAULT_MAP_CURSOR, MapEventHandler } from './map-event-handler';

/**
 * Handles changing the cursor as the user drags the map
 */
export class MapDragEventHandler extends MapEventHandler {
  protected addEventListeners(): void {
    this.createDraggingHandler();
  }

  private createDraggingHandler(): void {
    if (this.map) {
      this.events.add(
        this.map.on('pointermove', ({ dragging }) => {
          if (dragging) {
            this.setCursor(Cursor.grabbing);
          } else {
            this.setCursor(DEFAULT_MAP_CURSOR);
          }
        })
      );
    }
  }
}
