import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { WINDOW } from '../../shared-utilities/window-services';

import { StickinessChangeEvent } from './models';

/**
 * A directive to allow a dynamically sticky host element to receive information
 * about its target stickiness status (and scrollTop value) based on a stickyAt
 * threshold value at which it is to become/seize to be sticky.
 */
@Directive({
  selector: '[mcStickyTopElement]'
})
export class StickyTopElementDirective {

  /**
   * stickyAt is the distance from the top in pixels that, if scrolled down to it,
   * will trigger the host element to become sticky, or release from stickiness,
   * if scrolled up beyond it.
   */
  @Input()
  public set stickyAt(distanceFromTop: number) {
    this._stickyAt = distanceFromTop;
    this.updateStickiness();

  }
  public get stickyAt(): number {
    return this._stickyAt;
  }

  /**
   * Emits an event object indicating, if the host element should be sticky
   * or not based on the scroll relative to stickyAt. If the host element should
   * be sticky, it will include the current "scrollTop" position.
   */
  @Output() stickinessChange = new EventEmitter<StickinessChangeEvent>();

  private _stickyAt = 0;

  constructor(
    @Inject(WINDOW) private readonly window: Window | any,
    @Inject(DOCUMENT) private readonly document: Document | undefined
  ) {}

  private updateStickiness(): void {
    const scroll = this.window instanceof Window && this.window.pageYOffset !== undefined
      ? this.window.pageYOffset
      : this.document instanceof Document
        ? (this.document.documentElement || this.document.body).scrollTop
        : 0;
    if (scroll > this.stickyAt) {
      this.stickinessChange.emit({ sticky: true, scrollTop: scroll });
    } else if (scroll <= this.stickyAt) {
      this.stickinessChange.emit({ sticky: false, scrollTop: null });
    }
  }

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    this.updateStickiness();
  }

}
