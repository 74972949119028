import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import * as R from 'ramda';

import { Subscription } from 'rxjs';

import { GeneralLoadedContent, INITIAL_GENERAL_LOADING_CONTENT, LoadingRequestStatus } from '../../../shared-models';
import { DatasetEntryFilter, DatasetFilterService } from '../../../shared-utilities/search-sorting';

import {
  SearchableDropdownActionsUnion,
  SearchableDropdownChangeSearchtermAction,
  SearchableDropdownChangeSelectionAction,
  SearchableDropdownClosedAction,
  SearchableDropdownOpenedAction,
  SearchableDropdownRetryLoadAction,
  SearchableDropdownTheme,
  SearchableDropdownTouchedAction,
  SearchableItem,
  SearchableItemIDType
} from '../models';

@Component({
  selector: 'mc-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableDropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('dropdownButton', { static: false }) dropdownButtonElement: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() dropdownTogglePlaceholder: string = 'Select an item';
  /**
   * Flag indicating, if the dropdown button label should always be
   * determined by the current `dropdownTogglePlaceholder` string.
   *
   * If `false`, the place holder string will only be used, when
   * there is no selected item. Otherwise, the selected item label will
   * be shown.
   *
   * If `true`, it is up to the parent component to control the placeholder
   * string, if there should be a different label shown in selected and non-selected state.
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() alwaysUseDropdownTogglePlaceholder: boolean = false;

  @Input() selectedItemId: SearchableItemIDType | null = null;
  @Input() unfilteredItems: GeneralLoadedContent<SearchableItem[]> = INITIAL_GENERAL_LOADING_CONTENT;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() searchTerm: string = '';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() searchInputPlaceholder: string = 'Search items';
  /**
   * Label to use to display the count results for
   * the filtered list. e.g. `'Countries'` will show
   * the applicable search count results as e.g. "Countries (50)"
   *
   * Setting this input to `null` will prevent a search count
   * from being displayed.
   */
  @Input() countHeaderLabel: string | null = null;

  /**
   * Flag indicating whether the dropdown should include an empty
   * dropdown item, which allows the user to "reset" the selection
   * to "nothing selected", represented by `null` value
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() allowNullItem: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() loadingMessage: string = 'Please wait, loading items';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() noDataAvailableMessage: string = 'No items available';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() noMatchingResultsMessage: string = 'No results matching the search term';

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() disabled: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() disableSearchFeature: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() invalid: boolean = false;

  @Input() autoClose: boolean | 'outside' | 'inside' = true;
  @Input() container: 'body' | null = 'body';
  /**
   * A style theme for the dropdown picked from the support themes.
   */
  @Input() styleTheme: SearchableDropdownTheme = SearchableDropdownTheme.FORM_CONTROL;
  /**
   * Maximum height of scrollable area containing the dropdown items.
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() maxBodyHeight: string = '200px';
  /**
   * Dropdown width when opened.
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() menuWidth: string = '100%';
  /**
   * Base `z-index` to be used for dropdown menu where the default
   * ng-Bootstrap value is not appropriate for UI context. The
   * dropdown button will receive `z-index: baseZIndex + 1` when
   * the dropdown is opened.
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() baseZIndex: number = 0;

  @Input() isDisplayedWithinModal = false;

  @Output() action = new EventEmitter<SearchableDropdownActionsUnion>();

  @ViewChild(NgbDropdown, { static: false }) ngbDropdown: NgbDropdown;

  public filteredList: SearchableItem[] | null;

  public get countHeader(): string | null {
    if (
      this.countHeaderLabel !== null &&
      this.filteredList !== null
    ) {
      return `${this.countHeaderLabel} (${this.filteredList.length})`;
    } else {
      return null;
    }
  }

  public get dropdownToggleLabel(): string {
    return !this.alwaysUseDropdownTogglePlaceholder && this.selectedItem ? this.selectedItem.label : this.dropdownTogglePlaceholder;
  }

  public get noDataAvailable(): boolean {
    return this.unfilteredItems.loadingStatus === LoadingRequestStatus.loaded &&
      this.unfilteredItems.content !== null &&
      this.unfilteredItems.content.length === 0;
  }

  public get noMatchingResults(): boolean {
    return this.searchTerm.length > 0 && R.equals(this.filteredList, []);
  }

  public get showNullItem(): boolean {
    return this.allowNullItem && this.filteredList !== null && this.filteredList.length > 0;
  }

  public get searchable(): boolean {
    return !this.disableSearchFeature &&
      this.unfilteredItems.loadingStatus === LoadingRequestStatus.loaded &&
      this.unfilteredItems.content !== null &&
      this.unfilteredItems.content.length > 0;
  }

  public LoadingRequestStatus = LoadingRequestStatus;

  public selectedItem: SearchableItem | null = null;
  private entryFilter = new DatasetEntryFilter(['label']);

  private openChangeSubscription: Subscription;

  constructor(
    private readonly datasetFilterService: DatasetFilterService,
    private renderer: Renderer2
  ) { }


  public ngOnInit(): void {
    setTimeout(() => {
      const defaultDropdownWidth: number = this.dropdownButtonElement.nativeElement.getBoundingClientRect().width;
      this.menuWidth = defaultDropdownWidth.toString() + 'px';

      // If user has specified a z index input then use that, otherwise try using the CSS variable value, otherwise use a default value
      if (!this.baseZIndex) {
        const cssVariableZIndex = window.getComputedStyle(this.dropdownButtonElement.nativeElement).getPropertyValue('--z-index-dropdown');
        if (cssVariableZIndex) {
          this.baseZIndex = Number(cssVariableZIndex);
        } else {
          this.baseZIndex = 1000;
        }
      }
    }, 1);
  }

  public ngAfterViewInit(): void {
    this.openChangeSubscription = this.ngbDropdown.openChange.subscribe((opened: boolean) => {
      if (!opened) {
        this.action.emit(new SearchableDropdownTouchedAction());
        this.action.emit(new SearchableDropdownClosedAction());
      } else {
        this.action.emit(new SearchableDropdownOpenedAction());
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const searchTermChange = changes['searchTerm'];
    const unfilteredItemsChange = changes['unfilteredItems'];
    const selectedItemIdChange = changes['selectedItemId'];
    const disabledSearchFeatureChange = changes['disableSearchFeature'];

    if (unfilteredItemsChange || searchTermChange || disabledSearchFeatureChange) {
      this.updateFilteredList();
    }
    if (unfilteredItemsChange || selectedItemIdChange) {
      this.updateSelectedItem();
    }
  }

  public ngOnDestroy(): void {
    if (this.openChangeSubscription && !this.openChangeSubscription.closed) {
      this.openChangeSubscription.unsubscribe();
    }
  }

  public emitTouchedOnBlur(): void {
    if (!this.ngbDropdown.isOpen()) {
      this.action.emit(new SearchableDropdownTouchedAction());
    }
  }

  public isSelectedItem(item: SearchableItem, selectedItemId: SearchableItemIDType | null): boolean {
    return selectedItemId === item.id;
  }

  public retry(): void {
    this.action.emit(new SearchableDropdownRetryLoadAction());
  }

  public selectItem(item: SearchableItem | null): void {
    if (item !== this.selectedItem) {
      this.action.emit(new SearchableDropdownChangeSelectionAction(item !== null ? item : null));
      this.ngbDropdown.close();
    } else {
      this.ngbDropdown.close();
    }
  }

  public setSearchTerm(searchTerm: string): void {
    if (searchTerm !== this.entryFilter.contains) {
      this.entryFilter.contains = searchTerm;
      this.searchTerm = searchTerm;
      this.action.emit(new SearchableDropdownChangeSearchtermAction(searchTerm));
    }
  }

  public onTabKey(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      if (this.ngbDropdown?.isOpen()) {
        event.preventDefault();
        const searchInputElement = document.querySelector('.search-input__input');
        if (searchInputElement) {
          this.renderer.selectRootElement(searchInputElement).focus();
        }
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      this.ngbDropdown.toggle();
    }
  }

  private updateFilteredList(): void {
    if (
      this.unfilteredItems.loadingStatus === LoadingRequestStatus.loaded &&
      this.unfilteredItems.content !== null
    ) {
      if (this.disableSearchFeature || this.searchTerm === '') {
        this.entryFilter.contains = '';
        this.filteredList = this.unfilteredItems.content;
      } else {
        this.entryFilter.contains = this.searchTerm;
        this.filteredList = this.datasetFilterService.filter(this.unfilteredItems.content, [this.entryFilter]);
      }
    } else {
      this.filteredList = null;
    }
  }

  private updateSelectedItem(): void {
    if (
      this.unfilteredItems.loadingStatus === LoadingRequestStatus.loaded &&
      this.unfilteredItems.content !== null &&
      this.selectedItemId !== null
    ) {
      const allItems = this.unfilteredItems.content;
      this.selectedItem = allItems.find(item => item.id === this.selectedItemId) || null;
    } else {
      this.selectedItem = null;
    }
  }
}
