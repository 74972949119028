import { Injectable, NgZone } from '@angular/core';
import { UserPermissions } from '@grid-ui/api-models';
import { FeatureToggleService } from '@grid-ui/common';
import { WhoAmIService } from '@grid-ui/whoami';
import { PermissionsPollingService } from './permissions-polling.service';

export class PermissionSelector {

  static readonly COMMODITY_RISK = {
    ANALYSE: new PermissionSelector('always', 'deny', '/home'),
    HOME: new PermissionSelector('always', 'deny', '/commodity-risk/dashboard'),
    INSIGHTS: new PermissionSelector('commodity-risk', 'insights', '/home'),
    DASHBOARD: new PermissionSelector('commodity-risk', 'dashboard', '/commodity-risk/insights'),
    PROFILES: new PermissionSelector('always', 'deny', '/home'),
    VIEWS: new PermissionSelector('always', 'deny', '/home'),
    MODULE: new PermissionSelector('commodity-risk', 'module', '/home'),
  };

  static readonly COUNTRY_RISK = {
    ANALYSE: new PermissionSelector('country-risk', 'analyse', '/country-risk/insights'),
    HOME: new PermissionSelector('country-risk', 'home', '/country-risk/insights'),
    INSIGHTS: new PermissionSelector('country-risk', 'insights', '/country-risk/profiles'),
    PROFILES: new PermissionSelector('country-risk', 'profiles', '/home'),
    VIEWS: new PermissionSelector('country-risk', 'views', '/country-risk/insights'),
    MODULE: new PermissionSelector('country-risk', 'module', '/home'),
    ALERTS: new PermissionSelector('country-risk', 'alerts', '/country-risk/alerts'),
  };

  static readonly INDUSTRY = {
    MODULE: new PermissionSelector('industry', 'module', '/home'),
    BEST_WORST: new PermissionSelector('industry', 'bestWorst', '/industry'),
  };

  static readonly DATA_WIZARD = {
    EXTRACT: new PermissionSelector('data-wizard', 'extract', '/data-wizard/upload'),
    UPLOAD: new PermissionSelector('data-wizard', 'upload', '/home'),
    MODULE: new PermissionSelector('data-wizard', 'module', '/home'),
  };

  static readonly DOCUMENT_LIBRARY = {
    EXTRACTS: new PermissionSelector('document-library', 'module', '/document-library'),
    MODULE: new PermissionSelector('document-library', 'module', '/home'),
  };

  static readonly API_DOCS = {
    LINK: new PermissionSelector('api-docs', 'link'),
  };

  static readonly API_DOCS_INDUSTRY = {
    LINK: new PermissionSelector('api-docs-industry', 'link'),
  };

  static readonly SUBSCRIPTIONS = {
    COUNTRY_RISK: new PermissionSelector('subscriptions', 'country-risk', '/home'),
    INDUSTRY_RISK: new PermissionSelector('subscriptions', 'industry-risk', '/home'),
    COMMODITY_RISK: new PermissionSelector('always', 'deny', '/subscriptions/details'),
    DATA_WIZARD: new PermissionSelector('always', 'deny', '/subscriptions/details'),
    MODULE: new PermissionSelector('subscriptions', 'module', '/home'),
  };

  static readonly FAQ_LEARN = {
    ABOUT_MAPLECROFT: new PermissionSelector('always', 'allow'),
    COUNTRY_RISK: new PermissionSelector('country-risk', 'module', '/learn'),
    COMMODITY_RISK: new PermissionSelector('commodity-risk', 'module', '/learn'),
    INDUSTRY_RISK: new PermissionSelector('industry', 'module', '/learn'),
    DOCUMENT_LIBRARY: new PermissionSelector('document-library', 'module', '/learn'),
    DATA_WIZARD: new PermissionSelector('data-wizard', 'module', '/learn'),
    EMAIL_ALERTS: new PermissionSelector('always', 'deny'),
    MODULE: new PermissionSelector('always', 'allow'),
  };

  static readonly ALWAYS = {
    ALLOW: new PermissionSelector('always', 'allow'),
    DENY: new PermissionSelector('always', 'deny'),
  };

  static readonly GRID = {
    ACCOUNT_SETTINGS: new PermissionSelector('grid', 'account_settings')
  };

  // private - we only want the static instances, do not allow new instances to be created
  private constructor(
    public readonly module: string,
    public readonly area: string,
    public readonly routingDeniedRedirect?: string
  ) {
  }
}

@Injectable()
export class PermissionsService {
  public permissions: { [key: string]: { [key: string]: () => Promise<boolean> } } = {
    'always': {
      allow: () => Promise.resolve(true),
      deny: () => Promise.resolve(false),
    },
    'commodity-risk': {
      'dashboard': this.userHasCommodityRiskArrayPopulated.call(this),
      insights: this.userHasCommodityRiskArrayPopulated.call(this),
      module: this.userHasCommodityRiskArrayPopulated.call(this),
    },
    'country-risk': {
      analyse: this.userHasAnalyseAccess.bind(this),
      home: this.userHascountryRiskArrayPopulated.call(this, 'index'),
      insights: this.userHascountryRiskArrayPopulated.call(this, 'insights'),
      profiles: this.userHascountryRiskArrayPopulated.call(this, 'profiles'),
      views: this.userHascountryRiskArrayPopulated.call(this, 'index'),
      module: () => this.userHasCountryRiskAccess(),
      alerts: this.userHasCountryAlertsAccess.bind(this),
    },
    'data-wizard': {
      extract: this.userHasDataWizardExtractAccess.bind(this),
      module: () =>
        Promise.all([
          this.userHasDataWizardExtractAccess.bind(this)(),
          this.userHascountryRiskArrayPopulated.call(this, 'index')()
        ])
          .then((x) => x.includes(true)),
      upload: this.userHascountryRiskArrayPopulated.call(this, 'index')
    },
    'document-library': {
      module: () => this.whoAmIService.getPermissions().toPromise()
        .then(x => !!(x['document-library']))
    },
    'industry': {
      module: () => this.userHasIndustryRiskPopulated().call(this),
      bestWorst: () => Promise.resolve(!!this.featureToggleService.getFeatureToggles().industryBestWorst)
    },
    'api-docs': {
      link: this.userHasApiDocsAccess.bind(this)
    },
    'api-docs-industry': {
      link: this.userHasApiDocsIndustryAccess.bind(this),
    },
    'subscriptions': {
      'country-risk': () => this.userHasCountryRiskAccess(),
      'industry-risk': () => this.userHasIndustryRiskPopulated().call(this),
      module: () => Promise.all([
        this.userHasCountryRiskAccess(),
        this.userHasIndustryRiskPopulated().call(this),
      ])
        .then((x) => x.includes(true))
    },
    grid: {
      account_settings: () => this.userHasAccountSettingsAccess(),
    }
  };

  private userPermissions: Promise<UserPermissions>;

  constructor(
    private readonly whoAmIService: WhoAmIService,
    private readonly permissionsPollingService: PermissionsPollingService,
    private readonly featureToggleService: FeatureToggleService,
    private ngZone: NgZone
  ) {
    this.userPermissions = this.whoAmIService.getPermissions({ forceAPICall: true }).toPromise();

    this.ngZone.runOutsideAngular(() => {
      this.permissionsPollingService.startPolling().subscribe(permissions => {
        this.userPermissions = Promise.resolve(permissions.permissions);
      });
    });
  }

  public isAllowed(permission: PermissionSelector): Promise<boolean> {
    return this.permissions[permission.module][permission.area]();
  }

  private userHasIndustryRiskPopulated(): () => Promise<boolean> {
    return () => this.userPermissions.then(
      (userPermission: UserPermissions) =>
        !!this.featureToggleService.getFeatureToggles().industry
        && !!userPermission['industry-risk']
        && !!userPermission['industry-risk']['index']
        && !!userPermission['industry-risk']['index'].length
        && !!userPermission['industry-risk']['industry']
        && !!userPermission['industry-risk']['industry'].length);
  }

  private userHasAnalyseAccess(): Promise<boolean> {
    return Promise.all([
      this.userHascountryRiskArrayPopulated.call(this, 'index')(),
      this.userHascountryRiskArrayPopulated.call(this, 'countries')(),
    ]).then((x) => x.every(b => b));
  }

  private userHascountryRiskArrayPopulated(name: 'index' | 'profiles' | 'insights' | 'countries'): () => Promise<boolean> {
    return () => this.userPermissions.then(x => !!(x['country-risk'] && x['country-risk'][name] && x['country-risk'][name].length));
  }

  private userHasCommodityRiskArrayPopulated(): () => Promise<boolean> {
    return () => this.userPermissions.then(x => !!(x['commodity-risk']) && x['commodity-risk'].length > 0);
  }

  private userHasDataWizardExtractAccess(): Promise<boolean> {
    // TODO: remove boolean check once all environments are returning an object for this attribute
    return this.userPermissions.then(x => {
      if (!x['data-wizard']) {
        return false;
      }
      if (typeof x['data-wizard'] === 'boolean') {
        return x['data-wizard'];
      }
      return !!x['data-wizard'].extract;
    });
  }

  private userHasApiDocsAccess(): Promise<boolean> {
    return this.userPermissions.then(x => !!x['country-risk']?.documentation);
  }

  private userHasApiDocsIndustryAccess(): Promise<boolean> {
    return this.userPermissions.then(x => x['industry-risk']?.documentation);
  }

  private userHasCountryAlertsAccess(): Promise<boolean> {
    return this.userPermissions.then(x => !!(x['country-risk'] || {})['data-alerts']);
  }

  private userHasCountryRiskAccess(): Promise<boolean> {
    return Promise.all([
      this.userHascountryRiskArrayPopulated.call(this, 'index')(),
      this.userHascountryRiskArrayPopulated.call(this, 'insights')(),
      this.userHascountryRiskArrayPopulated.call(this, 'profiles')()
    ])
      .then((x) => x.includes(true));
  }

  private userHasAccountSettingsAccess(): Promise<boolean> {
    return this.userPermissions.then(permissions => permissions.grid?.account_settings ?? true);
  }
}
