import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import {
  LoadingRequestStatus,
  GeneralLoadedContent,
  INITIAL_GENERAL_LOADING_CONTENT
} from '../../../shared-models';

import { SearchInputComponent } from '../../search-input';
import {
  QuickSearchDropdownContent,
  QuickSearchDropdownItemType
} from '../models';

@Component({
  selector: 'grid-ui-quick-search-dropdown',
  templateUrl: './quick-search-dropdown.component.html',
  styleUrls: ['./quick-search-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchDropdownComponent implements OnInit, OnChanges, OnDestroy, AfterContentChecked {
  @Input() public dropdownData: GeneralLoadedContent<QuickSearchDropdownContent> = INITIAL_GENERAL_LOADING_CONTENT;
  /** The debounce time in ms to be passed to mc-search-input */
  @Input() public debounce = 200;
  /** Id to be assigned to the parent dropdown div element */
  @Input() public dropdownId!: string;
  /** Message to show on the dropdown button */
  @Input() public dropdownMessage!: string;
  /** Placeholder text for search input box */
  @Input() public placeholder: string | undefined;
  /** Whether to show the search box or not */
  @Input() public searchable = true;

  /** Fires when the user has changed the search term */
  @Output() public hintChange = new EventEmitter<string>();
  /** Fires when the dropdown is opened */
  @Output() public opened = new EventEmitter();
  /**
   * Fires when the user clicks or presses enter on a result and
   * emits the Country Risk Index id or
   * Country/Site geo_id of the selected result.
   */
  @Output() public resultSelected = new EventEmitter<QuickSearchDropdownItemType>();

  @ViewChild(NgbDropdown, { static: true }) public dropdown!: NgbDropdown;
  @ViewChild('searchInputContainer', { read: ElementRef }) public searchInputContainer!: ElementRef<HTMLDivElement>;
  @ViewChild(SearchInputComponent) public searchInputComponent!: SearchInputComponent;

  public activeIndex = 0;
  public errorPrimaryAction = 'Try Again';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public hint: string = '';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly RequestStatus = LoadingRequestStatus;

  private dropdownChangeSubscription!: Subscription;
  private pendingToNotifyOpen = false;

  public ngOnInit(): void {
    this.dropdownChangeSubscription =
      this.dropdown.openChange.subscribe((change: boolean) => {
        this.pendingToNotifyOpen = change;
        if (
          change === true
          && (!this.dropdownData.content || this.dropdownData.content.items.length === 0)
        ) {
          if (this.searchable) {
            this.searchInputComponent.clearSearchTerm();
          }
          this.opened.emit();
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'requestStatus') &&
      !changes.requestStatus.firstChange &&
      changes.requestStatus.currentValue !== LoadingRequestStatus.loading) {
      this.pendingToNotifyOpen = true;
    }
    if (Object.prototype.hasOwnProperty.call(changes, 'results')) {
      this.activeIndex = 0;
    }
  }

  public ngOnDestroy(): void {
    if (this.dropdownChangeSubscription && !this.dropdownChangeSubscription.closed) {
      this.dropdownChangeSubscription.unsubscribe();
    }
  }

  /**
   * The openChange event from NgbDropdown is fired before the dropdown is actually
   * open, so we can't give focus to the mc-search-input as it's still hidden.
   * Here we wait for the mc-search-input container to become visible to change
   * the send an event to SearchInputComponent.
   */
  public ngAfterContentChecked(): void {
    if (this.pendingToNotifyOpen &&
      this.searchable &&
      this.searchInputContainer.nativeElement.offsetParent &&
      this.searchInputComponent) {
      this.searchInputComponent.focus();
      this.pendingToNotifyOpen = false;
    }
  }

  public reloadWithHint(value: string): void {
    this.hint = value;
    this.hintChange.emit(value);
  }

  public selectedResultByClick(item: QuickSearchDropdownItemType): void {
    this.resultSelected.emit(item);
  }

  public setActive(index: number): void {
    this.activeIndex = index;
  }

  @HostListener('keydown', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (this.dropdownData.content) {
      let result: any;
      switch (event.key) {
        case 'ArrowDown':
        case 'Down':
          event.preventDefault();
          this.activeIndex = this.activeIndex >= this.dropdownData.content.items.length - 1 ?
            0 : this.activeIndex + 1;
          break;
        case 'ArrowUp':
        case 'Up':
          event.preventDefault();
          this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : this.dropdownData.content.items.length - 1;
          break;
        case 'Enter':
          event.preventDefault();
          result = this.dropdownData.content.items[this.activeIndex];
          this.resultSelected.emit(result);
          break;
      }
    }
  }
}
