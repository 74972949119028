import Feature, { FeatureLike } from 'ol/Feature';
import { Fill, Icon, Style, Text } from 'ol/style';
import { StyleColorPalette } from '../color/style-color-palette';
import { LayerStyle } from './layer-style';

const CLUSTER_MIN_RADIUS = 28;
const CLUSTER_MAX_RADIUS = 40;
const CLUSTER_STROKE_WIDTH = 2;
const CLUSTER_SHADOW_OFFSET = 8;
const FONT_FAMILY = `Roboto, sans-serif`;

interface CanvasContext {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
}

export const CLUSTER_DIAMETER = (CLUSTER_MAX_RADIUS + CLUSTER_STROKE_WIDTH) * 2;

/**
 * A map style for sites.
 */
export class SitesAndScoresLayerStyle extends LayerStyle {
  constructor(colorPalette: StyleColorPalette, private readonly document: Document) {
    super(colorPalette);
  }

  /**
   * Rebuild all styles.
   *
   * This is a mirror of method calls within the constructor
   * and should be refactored to avoid the code duplication.
   */
  protected rebuildStyleCache() {}

  /**
   * Handy method to find out if an OpenLayers feature is a cluster.
   *
   * Used for dynamic styling on sites.
   *
   * @param feature The OpenLayers feature.
   * @returns true if the feature is a cluster.
   */
  private isCluster(feature: FeatureLike): boolean {
    if (!feature || !feature.get('features')) {
      return false;
    }
    return feature.get('features').length > 1;
  }

  /**
   * Scales a cluster size to a circle radius.
   *
   * Used to make cluster groups scale based on their size.
   * @param value The number of features in the cluster
   * @returns A scaled circle radius
   */
  private scaleClusterCircleRadius(value: number): number {
    const clusterSizeMin = 2;
    const clusterSizeMax = 14;
    const circleRadiusMin = CLUSTER_MIN_RADIUS;
    const circleRadiusMax = CLUSTER_MAX_RADIUS;

    if (value <= clusterSizeMin) {
      return circleRadiusMin;
    } else if (value >= clusterSizeMax) {
      return circleRadiusMax;
    } else {
      return (
        ((circleRadiusMax - circleRadiusMin) * (value - clusterSizeMin)) /
          (clusterSizeMax - clusterSizeMin) +
        circleRadiusMin
      );
    }
  }

  /**
   * Creates a pie chart to represent risk colours within a cluster.
   *
   * This lacks interactivity because the created canvas is effectively
   * a static image.  D3 might be a better library choice for doing this
   * kind of viz.
   *
   * @param feature The cluster feature.
   * @param radius The circle radius.
   * @returns An OpenLayers Style.
   */
  private createClusterGroupStyle(feature: FeatureLike, radius: number): Style {
    const features: Feature[] = feature.get('features');

    // Sort features based on the 'score' value
    features.sort((a, b) => b.get('score') - a.get('score'));

    const clusterSize = (radius + CLUSTER_STROKE_WIDTH) * 2 + CLUSTER_SHADOW_OFFSET;
    const { canvas, context } = this.createCanvas(clusterSize, clusterSize);
    const featureCount = features.length;

    this.styleClusterDonut(
      radius,
      features,
      featureCount,
      clusterSize,
      context,
    );

    const sitesTextFontSize = 11;

    this.styleClusterText(clusterSize, context, sitesTextFontSize);

    return new Style({
      zIndex: 20,
      image: new Icon({
        img: canvas,
        width: clusterSize,
        height: clusterSize,
      }),
      text: new Text({
        font: `600 18px ${FONT_FAMILY}`,
        offsetY: sitesTextFontSize / -2,
        text: `${featureCount}`,
        fill: new Fill({
          color: this.colorPalette.clusterGroupTextFill,
        }),
      }),
    });
  }

  /**
   * Adds the cluster donut styles to the canvas context
   */
  private styleClusterDonut(
    radius: number,
    features: Feature[],
    featureCount: number,
    clusterSize: number,
    context: CanvasRenderingContext2D,
  ): void {
    const strokeStyle = this.colorPalette.clusterGroupBackgroundFill;

    // Adjust this value to control the thickness of the donut
    const donutInnerRadius = radius / 1.4;

    let startAngle = 0;
    let endAngle = 0;
    const centerX = clusterSize / 2;
    const centerY = clusterSize / 2;


    // Equal slice for simplicity
    const sliceAngle = (2 * Math.PI) / featureCount;

    // Draw donut slices
    features.forEach((feature: Feature) => {
      const color: string = feature.get('color');
      endAngle = startAngle + sliceAngle;

      context.beginPath();
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.arc(
        centerX,
        centerY,
        donutInnerRadius,
        endAngle,
        startAngle,
        true
      );
      context.closePath();
      context.fillStyle = color;
      context.fill();

      startAngle = endAngle;
    });

    // Fill the inner circle to ensure it has the correct color and is not just stroked
    context.beginPath();
    context.arc(centerX, centerY, donutInnerRadius, 0, 2 * Math.PI);
    context.fillStyle = this.colorPalette.clusterGroupBackgroundFill;
    context.fill();

    // Set shadow properties
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 2;
    context.shadowBlur = 3;
    context.shadowColor = 'rgba(0,0,0,0.3)';

    // Draw white stroke around the donut chart outer edge
    context.beginPath();
    context.arc(centerX, centerY, radius + CLUSTER_STROKE_WIDTH / 2, 0, 2 * Math.PI);
    context.strokeStyle = strokeStyle;
    context.lineWidth = CLUSTER_STROKE_WIDTH;
    context.stroke();

    // Reset shadow properties to avoid affecting subsequent drawings
    context.shadowColor = 'transparent';

    // Add stroke around the inner edge
    context.beginPath();
    context.arc(
      centerX,
      centerY,
      donutInnerRadius - CLUSTER_STROKE_WIDTH / 2,
      0,
      2 * Math.PI
    );
    context.stroke();
  }

  /**
   * Adds the cluster donut "sites" text styles
   */
  private styleClusterText(
    clusterSize: number,
    context: CanvasRenderingContext2D,
    fontSize: number
  ): void {
    // Set font properties
    context.font = `${fontSize}px ${FONT_FAMILY}`;

    // Horizontally centre the text
    context.textAlign = 'center';

    // Vertically centre the text
    context.textBaseline = 'middle';

    // Text color
    context.fillStyle = this.colorPalette.clusterGroupTextFill;

    // Center the title horizontally
    const titleX = clusterSize / 2;

    // Position 'sites' just below the number of sites
    const titleY = clusterSize / 2 + fontSize - 1;

    // Draw the title
    context.fillText('sites', titleX, titleY);
  }

  /**
   * Creates a score tooltip style for non-clustered sites.
   */
  private createSiteScoreTooltipStyle(feature: FeatureLike): Style {
    const width = 46;
    const height = 36;
    const padding = 3;
    const strokeWidth = 2;
    const cornerRadius = 3;
    const triangleHeight = 4;
    const triangleWidth = 6;
    const fontSize = 13;

    const { canvas, context } = this.createCanvas(width, height);

    // Get the color from the feature attributes
    const color = feature.get('color');
    const textColor = feature.get('textColor');

    const addShadow = (ctx: CanvasRenderingContext2D) => {
      // Set shadow properties
      ctx.shadowOffsetX = 0; // Horizontal shadow offset
      ctx.shadowOffsetY = 1; // Vertical shadow offset
      ctx.shadowBlur = 2; // How much the shadow should be blurred
      ctx.shadowColor = 'rgba(0,0,0,0.3)'; // Shadow color
    };

    // Draw a rectangle with the feature's color
    if (context && color) {
      // Add the shadow
      addShadow(context);

      // Create the rounded stroke using a rounded rectangle
      const strokeRectWidth = width - (strokeWidth * 2);

      // Height reduced to make space for the marker triangle
      const strokeRectHeight = height - (strokeWidth * 2) - triangleHeight;
      const strokeRectX = (width - strokeRectWidth) / 2;
      const strokeRectY = (height - strokeRectHeight) / 2;

      context.fillStyle = this.colorPalette.clusterGroupBackgroundFill;
      context.beginPath();

      try {
        (context as any).roundRect(
          strokeRectX,
          strokeRectY,
          strokeRectWidth,
          strokeRectHeight,
          cornerRadius
        );
      } catch {
        // Fallback to a non-rounded rectangle if the browser doesn't support it.
        context.rect(
          strokeRectX,
          strokeRectY,
          strokeRectWidth,
          strokeRectHeight
        );
      }

      context.fill();

      // Draw the marker triangle
      context.beginPath();
      context.moveTo(
        strokeRectX + strokeRectWidth / 2,
        strokeRectY + strokeRectHeight + triangleHeight
      );

      // Start at the bottom center of the rectangle
      context.lineTo(
        strokeRectX + strokeRectWidth / 2 - triangleWidth / 2,
        strokeRectY + strokeRectHeight
      );

      // Left corner of the inverted triangle
      context.lineTo(
        strokeRectX + strokeRectWidth / 2 + triangleWidth / 2,
        strokeRectY + strokeRectHeight
      );

      // Right corner of the inverted triangle
      // Return to the start point
      context.closePath();
      context.fillStyle = this.colorPalette.clusterGroupBackgroundFill;

      // Fill the triangle
      context.fill();

      // Remove the shadow for the score
      context.shadowColor = 'transparent';

      // Create the score rectangle
      const colorRectWidth = width - (padding * 2) - (strokeWidth * 2);
      const colorRectHeight = height - (padding * 2) - (strokeWidth * 2) - triangleHeight;
      const colorRectX = (width - colorRectWidth) / 2;
      const colorRectY = (height - colorRectHeight) / 2;

      // Ensure inner radius is always at least 2
      const colorRectRadii = Math.max(cornerRadius - padding, 2);

      context.fillStyle = color;
      context.beginPath();

      try {
        (context as any).roundRect(
          colorRectX,
          colorRectY,
          colorRectWidth,
          colorRectHeight,
          colorRectRadii
        );
      } catch {
        // Fallback to a non-rounded rectangle if the browser doesn't support it.
        context.rect(colorRectX, colorRectY, colorRectWidth, colorRectHeight);
      }

      // Fill the score
      context.fill();
    }

    // Create an OpenLayers icon style with the canvas as the image source
    const style = new Style({
      image: new Icon({
        img: canvas,
        width: width,
        height: height,
        // Anchor at the bottom center
        anchor: [0.5, 1],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
      }),
      text: new Text({
        text: feature.get('score'),
        font: `400 ${fontSize}px ${FONT_FAMILY}`,
        fill: new Fill({ color: textColor }),
        offsetY: fontSize - height + triangleHeight + strokeWidth,
      }),
    });

    return style;
  }

  /**
   * The Style function which OpenLayers will need to call.
   *
   * @example
   *
   * const mapStyle = new SitesMapStyle(this.props.colorPalette);
   *
   * new VectorTile({
   *   style: (f: FeatureLike, r: number) => mapStyle.getStyleFunction(f, r)
   * });
   */
  public getStyleFunction(feature: FeatureLike): Style | void {
    if (this.isCluster(feature)) {
      // Clusters will get a donut chart. Interactivity handled by map-events.ts
      const scaledRadius = this.scaleClusterCircleRadius(
        feature.get('features').length
      );
      return this.createClusterGroupStyle(feature, scaledRadius);
    } else {
      // Single features will get a tooltip.
      return this.createSiteScoreTooltipStyle(feature.get('features')[0]);
    }
  }

  /**
   * Creates a canvas and context that is scaled relative to the device's PPI.
   */
  private createCanvas(width: number, height: number): CanvasContext {
    const window = this.document.defaultView;

    if (!window) {
      throw new Error('window is undefined');
    }

    // A minimum ratio of 2 helps create a crisper image
    const ratio = Math.min(window.devicePixelRatio, 2);
    const canvas = this.document.createElement('canvas');

    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Could not get canvas context');
    }

    context.scale(ratio, ratio);

    return { canvas, context };
  }
}
