import { Action } from '@ngrx/store';
import { PollConfig, PollStatusEnum } from '../../../../shared-models';

export enum PollingActionTypes {
  StartPolling = '[Polling (GRiD)] Start New Poll',
  ContinueOngoingPoll = '[Polling (GRiD)] Continue Ongoing Poll',
  Poll = '[Polling (GRiD)] Poll',
  PollingComplete = '[Polling (GRiD)] Polling Complete',
  PollInitialCallError = '[Polling (GRiD)] Initial Call Error',
  PollStatusCallError = '[Polling (GRiD)] Status Call Error',
  PollStatusCallScoringInProgressError = '[Polling (GRiD)] Status Call Scoring Ongoing Error',
  RemovePoll = '[Polling (GRiD)] Remove Poll',
  UpdatePollStatus = '[Polling (GRiD)] Update Poll Status',
  UpdateExtractId = '[Polling (GRiD)] Update Extract Id',
  ContinuePollingSuccess = '[Polling (GRiD)] Continue Polling Success',
  UserCancel = '[Polling (GRiD)] Set userCancelled to true',
}

export class StartPolling implements Action {
  public readonly type = PollingActionTypes.StartPolling;
  constructor(public payload: { poll: PollConfig }) { }
}

export class ContinueOngoingPoll implements Action {
  public readonly type = PollingActionTypes.ContinueOngoingPoll;
  constructor(public payload: { poll: PollConfig }) { }
}

export class RemovePoll implements Action {
  public readonly type = PollingActionTypes.RemovePoll;
  constructor(public payload: { id: number }) { }
}

export class Poll implements Action {
  public readonly type = PollingActionTypes.Poll;
  constructor(public payload: { poll: PollConfig }) { }
}

export class PollingComplete implements Action {
  public readonly type = PollingActionTypes.PollingComplete;
  constructor(public payload: { id: number }) { }
}

export class PollInitialCallError implements Action {
  public readonly type = PollingActionTypes.PollInitialCallError;
  constructor(public payload: { id: number }) { }
}

export class PollStatusCallError implements Action {
  public readonly type = PollingActionTypes.PollStatusCallError;
  constructor(public payload: { id: number }) { }
}

export class PollStatusCallScoringInProgressError implements Action {
  public readonly type = PollingActionTypes.PollStatusCallScoringInProgressError;
  constructor(public payload: { id: number }) { }
}

export class UpdatePollStatus implements Action {
  public readonly type = PollingActionTypes.UpdatePollStatus;
  constructor(public payload: { id: number; status: PollStatusEnum }) {}
}

export class UpdateExtractId implements Action {
  public readonly type = PollingActionTypes.UpdateExtractId;
  constructor(public payload: { id: number; extractId: string }) {}
}

export class ContinuePollingSuccess implements Action {
  public readonly type = PollingActionTypes.ContinuePollingSuccess;
  constructor(public payload: { response: any; poll: PollConfig }) {}
}

export class UserCancel implements Action {
  public readonly type = PollingActionTypes.UserCancel;
  constructor(public payload: { id: number }) { }
}

export type PollingActions =
  Poll |
  PollingComplete |
  PollInitialCallError |
  PollStatusCallError |
  PollStatusCallScoringInProgressError |
  RemovePoll |
  StartPolling |
  ContinueOngoingPoll |
  UpdatePollStatus |
  UpdateExtractId |
  ContinuePollingSuccess |
  UserCancel;
