/**
 * We're displaying the risk score on top of the risk colour
 * which can be hard to read depending on its luminance.
 * Swap dynamically between black/white to solve this problem.
 */
export function tryGetTextColourFromLuminance (rgbString: string): string{
  const match = rgbString.match(/\d+/g);

  if (match) {
    const [r, g, b] = match.map(Number);
    // Calculate the luminance
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    return luminance > 170 ? 'black' : 'white';
  } else {
    return 'black'; // Safety just in case we didn't pass correct RGB values (unlikely)
  }
};
