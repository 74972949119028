import { Cursor } from '@grid-ui/common';
import { Map } from 'ol';
import { EventsKey } from 'ol/events';

export const DEFAULT_MAP_CURSOR = Cursor.grab;

export type SetCursorFn = (cursor: Cursor) => void;

export interface MapEventHandlerOptions {
  readonly setCursor: SetCursorFn;
}

/**
 * An abstact class for a map event handler.
 */
export abstract class MapEventHandler {
  // Reference to the OL Map instance
  protected map?: Map;

  // Callback function for changing the cursor on the map
  protected readonly setCursor: SetCursorFn;

  protected readonly events = new Set<EventsKey>();

  constructor({ setCursor }: MapEventHandlerOptions) {
    this.setCursor = setCursor;
  }

  public activate(map: Map): void {
    this.map = map;

    this.addEventListeners();
  }

  public dispose(): void {
    this.events.forEach(({ type, listener }) => this.map?.removeEventListener(type, listener));

    this.events.clear();
  }

  protected abstract addEventListeners(): void;
}







