import { NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { FeatureToggleGuard, WindowUtilitiesService } from '@grid-ui/common';
import { CanLoadPermissionGuard, PermissionSelector, SitePermissionGuard } from '@grid-ui/permissions';
import { RoutedErrorComponent, } from './core';
import { GridComponent } from './grid/grid.component';
import { HomeComponent } from './home';
import { PageNotFoundComponent } from './shared-ui-components/page-not-found';

export const routes: Routes = [
  {
    path: 'accounts',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
  },
  {
    path: '',
    component: GridComponent,
    canActivate: [
      SitePermissionGuard
    ],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'portal', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'account-settings',
        loadChildren: () => import('@grid-ui/account-settings').then(m => m.AccountSettingsModule),
        canLoad: [CanLoadPermissionGuard],
        data: {
          permissionGuard: PermissionSelector.GRID.ACCOUNT_SETTINGS
        },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { breadcrumb: 'Home' }
      },
      {
        path: 'error/:code',
        component: RoutedErrorComponent,
        data: { breadcrumb: false }
      },
      {
        path: 'error',
        component: RoutedErrorComponent,
        data: { breadcrumb: false }
      },
      {
        path: 'commodity-risk',
        loadChildren: () => import('./commodity-risk/commodity-risk.module').then(m => m.CommodityRiskModule),
        canLoad: [FeatureToggleGuard, CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Commodity Risk',
          permissionGuard: PermissionSelector.COMMODITY_RISK.MODULE,
          featureToggleName: 'commodityRisk'
        }
      },
      {
        path: 'country-risk',
        loadChildren: () => import('./country-risk/country-risk.module').then(m => m.CountryRiskModule),
        canLoad: [CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Country Risk',
          permissionGuard: PermissionSelector.COUNTRY_RISK.MODULE
        }
      },
      {
        path: 'data-wizard',
        loadChildren: () => import('./data-wizard/data-wizard.module').then(m => m.DataWizardModule),
        canLoad: [CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Data Wizard',
          permissionGuard: PermissionSelector.DATA_WIZARD.MODULE
        }
      },
      {
        path: 'document-library',
        loadChildren: () => import('./document-library/document-library.module').then(m => m.DocumentLibraryModule),
        canLoad: [CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Document Library',
          permissionGuard: PermissionSelector.DOCUMENT_LIBRARY.MODULE
        }
      },
      {
        path: 'grid-updates',
        loadChildren: () => import('./feature-release/feature-release.module').then(m => m.FeatureReleaseModule),
        data: { breadcrumb: 'GRiD Updates' },
      },
      {
        path: 'industry',
        loadChildren: () => import('@grid-ui/industry').then(m => m.IndustryModule),
        canLoad: [CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Industry Risk',
          permissionGuard: PermissionSelector.INDUSTRY.MODULE
        }
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
        canLoad: [FeatureToggleGuard, CanLoadPermissionGuard],
        data: {
          breadcrumb: 'Your Subscription',
          featureToggleName: 'subscriptions',
          permissionGuard: PermissionSelector.SUBSCRIPTIONS.MODULE
        }
      },
      {
        path: 'learn',
        loadChildren: () => import('./learn/learn.module').then(m => m.LearnModule),
        data: { breadcrumb: 'Learn' }
      },
      {
        path: 'not-found',
        component: PageNotFoundComponent,
        data: { breadcrumb: 'Page Not Found' }
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { breadcrumb: 'Page Not Found' }
      },
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router, windowUtilitiesService: WindowUtilitiesService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !router.getCurrentNavigation()?.extras.state?.preventScrollToTop) {
        windowUtilitiesService.scrollTo(0);
      }
    });
  }
}
