import { ActionReducerMap } from '@ngrx/store';

import { whoAmIReducer, WhoAmIState } from '@grid-ui/whoami';
export interface AppState {
  whoAmI: WhoAmIState;
}

export const coreReducers: ActionReducerMap<AppState> = {
  whoAmI: whoAmIReducer
};
