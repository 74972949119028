/* eslint-disable @typescript-eslint/naming-convention */
export enum StoreDataWrapperStatus {
  INITIAL,
  LOADING,
  LOADED,
  FAILED
}

export interface StoreDataWrapper<T> {
  data: T;
  status: StoreDataWrapperStatus;
  statusString: string;
}
