import { CountryCode } from '@grid-ui/common';
import Layer from 'ol/layer/Layer';
import { ScoreStringAndColor } from '../models';
import {
  MapLayerEventHandler,
  MapLayerEventHandlerOptions,
} from './map-layer-event-handler';

/**
 * A dictionary of country codes and their risk scores as strings, along with colours.
 */
export type CountryCodeColoredStringScores = Map<CountryCode, ScoreStringAndColor>;

export type CountryNameMap = Map<CountryCode, string>;

export interface NationalCountryRiskScoreEventHandlerOptions
  extends MapLayerEventHandlerOptions {
  /**
   * An array of layer class names. One layer should
   * include iso_alpha2 codes. Any others provided will be considered for
   * filtering which can resolve an issue where the tooltip is not hidden
   * when a higher ordered layer is hovered over, for example site clusters.
   */
  readonly layerClassNames: string[];

  /**
   * A dictionary of country codes and their score as strings.
   */
  readonly countryCodeScores: CountryCodeColoredStringScores;

  /**
   * A dictionary of country codes and their country names.
   */
  readonly countryNameMap: CountryNameMap;
}

/**
 * Handles national country risk score hover over.
 */
export class NationalCountryRiskScoreEventHandler extends MapLayerEventHandler {
  private readonly layerClassNames: string[];
  private readonly countryCodeScores: CountryCodeColoredStringScores;
  private readonly countryNameMap: CountryNameMap;

  constructor({
    setCursor,
    tooltip,
    layerClassNames,
    countryCodeScores,
    countryNameMap,
  }: NationalCountryRiskScoreEventHandlerOptions) {
    super({ setCursor, tooltip });

    this.layerClassNames = layerClassNames;
    this.countryCodeScores = countryCodeScores;
    this.countryNameMap = countryNameMap;
  }

  protected addEventListeners(): void {
    this.createPointerMoveHandler();
  }

  protected layerFilter(layer: Layer): boolean {
    return this.layerClassNames.includes(layer.getClassName());
  }

  /**
   * When the mouse hovers over a feature we'll
   * set the cursor to a pointer (hand).
   */
  private createPointerMoveHandler(): void {
    if (this.map) {
      this.subscriptions.add(
        this.cursorFeature$.subscribe(({ feature, pixel }) => {
          if (feature) {
            const countryCode = feature.get('iso_alpha2');

            if (this.countryCodeScores.has(countryCode)) {
              const country = this.countryCodeScores.get(countryCode);;

              if (country) {
                this.tooltip.setScore(country);
              }

              const [x, y] = pixel;

              this.tooltip.setPosition({
                x,
                y,
              });

              this.tooltip.setVisible(true);

              let subtitle = countryCode;

              if (this.countryNameMap.has(countryCode)) {
                subtitle = this.countryNameMap.get(countryCode) ?? countryCode;
              }

              this.tooltip.setSubtitle(subtitle);

              return;
            }
          }

          this.tooltip.setVisible(false);
        })
      );
    }
  }
}
