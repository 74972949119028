import { Injectable, InjectionToken, Inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Observable, interval } from 'rxjs';
import { WhoAmI } from '@grid-ui/api-models';
import { WhoAmIService } from '@grid-ui/whoami';

export const REFRESH_PERMISSIONS = new InjectionToken<number>('REFRESH_PERMISSIONS');

@Injectable()
export class PermissionsPollingService {
  constructor(
    private readonly whoAmIService: WhoAmIService,
    @Inject(REFRESH_PERMISSIONS) private readonly refreshInterval: number
  ) { }

  public startPolling(): Observable<WhoAmI> {
    return interval(this.refreshInterval).pipe(
      switchMap(() => this.whoAmIService.getWhoAmI({ forceAPICall: true }))
    );
  }
}
