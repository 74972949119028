import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { WindowUtilitiesService } from '@grid-ui/common';
import { gridEnvironment } from '@grid-ui/environment';
import { WhoAmIService } from '@grid-ui/whoami';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SitePermissionGuard implements CanActivate {

  constructor(
    private readonly whoAmIService: WhoAmIService,
    private readonly windowUtilitiesService: WindowUtilitiesService,
  ) { }

  public canActivate(): Observable<boolean> | boolean {
    return this.whoAmIService.getPermissions().pipe(
      map(permissions => {
        if (gridEnvironment.bypassNoAccessCheck || permissions['grid-application']) {
          return true;
        }
        this.windowUtilitiesService.setHref('/no-access.html');
        return false;
      })
    );
  }
}
