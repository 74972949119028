import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionSelector } from '@grid-ui/permissions';
import { environment } from '../../../../environments/environment';
import { RoutingNavItem } from '../../../shared-models';

@Component({
  selector: 'mc-top-right-help-menu',
  templateUrl: './top-right-help-menu.component.html',
  styleUrls: ['./top-right-help-menu.component.scss'],
})
export class TopRightHelpMenuComponent {

  @Input() helpMenuRoutes: RoutingNavItem[] | null = null;
  @Input() isHome = false;

  public readonly PermissionSelector = PermissionSelector;
  public isMenuOpen = false;

  constructor(private router: Router) {}

  public onMouseEnter(e: Event): void {
    this.isMenuOpen = true;
  }

  public onMouseLeave(e: Event): void {
    this.closeMenu();
  }

  public onRouteSelected(route: any[]): void {
    this.closeMenu();
    this.router.navigate([route[0]]);
  }

  public get industryApiDocLink(): string {
    return environment.industryFrontendApi + 'industry-api-doc.pdf';
  }

  private closeMenu(): void {
    this.isMenuOpen = false;
  }
}
