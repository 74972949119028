import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IconSize = 'small' | 'medium' | 'large' | 'very-large';
export type Position = 'right' | 'bottom';
export type FontSize = 'small' | 'normal';
export type Color = 'primary' | 'secondary';

@Component({
  selector: 'mc-loading',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'data-hook': 'loading-spinner'
  },
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
  @Input() public loadingMessage = 'Loading, please wait.';
  @Input() public messagePosition: Position = 'bottom';
  @Input() public size: IconSize = 'small';
  @Input() public fontSize: FontSize = 'normal';
  @Input() public color: Color = 'primary';
}
