import { Injectable, Renderer2 } from '@angular/core';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { Observable, Subject, of } from 'rxjs';

import { ScorePanelAsImageRequiredData } from '../model';

@Injectable({
  providedIn: 'root'
})
export class DownloadScorePanelAsImageService {

  // Simple method to convert all the svgs in the cloned instance to canvas
  public convertAllSvgs(data: ScorePanelAsImageRequiredData): void {
    if (data.cloneRef) {
      const allSvgs = data.cloneRef.nativeElement.querySelectorAll('svg');
      allSvgs.forEach((element: SVGElement) => {
        const parent = element.parentElement ? element.parentElement : element.parentNode;
        if (parent) {
          parent.appendChild(this.convertSvg(element));
          parent.removeChild(element);
        }
      });
    }
  }

  // Return a canvas elemente based on a given svg, this is done so that the
  public convertHtmlToImageAndDownload(renderer: Renderer2, data: ScorePanelAsImageRequiredData): Observable<void> {
    // Method to convert the html element to a png and download it to the user

    // If this component has been setup incorrectly, you can't download the
    // image
    if (data.linkedElement) {
      const resultSubject: Subject<void> = new Subject();
      // Clone the reference and pretend it's an html element to help with
      // styling
      const cloneLink = data.linkedElement.cloneNode(true);

      // Do this only for scoreCard.
      if (data.linkedElement.classList.contains('grid-ui-image-download-container')) {
        renderer.setStyle(cloneLink.childNodes[0].childNodes[3], 'height', 'auto');
      }

      // Do this only for scoreMap.
      if (data.linkedElement.classList.contains('score-map-selector')) {
        renderer.setStyle(cloneLink.childNodes[1], 'display', 'none');
      }

      // Fetch the old width to make sure it remains the same + include the
      // extra padding involved
      if (!data.optionalStyle) {
        data.optionalStyle = {};
      }
      data.optionalStyle.width = (data.linkedElement.clientWidth + 100) + 'px';

      // Add the cloneRef to the hidden offscreen element, this is required
      // for htmlcanvas to find it
      data.cloneRef.nativeElement.appendChild(cloneLink);

      // remove text-shadow from element to due too bug in html2canvas: niklasvh/html2canvas#1638
      data.cloneRef.nativeElement.style.textShadow = '0 0 0 transparent';

      // Add title and logo
      // append header title
      if (data.headerRef) {
        renderer.setStyle(cloneLink, 'padding-top', '50px');

        const header = data.headerRef.nativeElement.cloneNode(true) as HTMLElement;
        // remove buttons from header
        const buttonsWrapper = header.getElementsByClassName('header-buttons-wrapper');
        if (buttonsWrapper && buttonsWrapper.length > 0) {
          header.removeChild(buttonsWrapper[0]);
        }
        renderer.setStyle(header, 'width', '100%');
        renderer.setStyle(header, 'margin-bottom', '0');
        renderer.setStyle(header, 'left', '0');
        renderer.setStyle(header, 'top', '0');

        cloneLink.insertBefore(header, cloneLink.firstChild);
      } else {
        cloneLink.insertBefore(this.createTitle(data), cloneLink.firstChild);
      }

      if (data.addScoreLegendToImage) {
        cloneLink.appendChild(this.createLegend(data));
      }

      cloneLink.appendChild(this.createLogo());

      // remove elements that should not be hidden from image
      if (data.hideElementSelector) {
        const hiddenElements = (data.cloneRef.nativeElement as Element).querySelectorAll(data.hideElementSelector);
        for (let i = 0; i < hiddenElements.length; i++) {
          hiddenElements[i].remove();
        }
      }

      // Wrap in a timeout to allow the styles to take hold before saving it
      // into a png
      setTimeout(() => {
        // Convert all the svgs found into canvas elements
        this.convertAllSvgs(data);

        // Convert the rest of the html into a canvas element
        html2canvas(data.cloneRef.nativeElement, {
          allowTaint: true,
          logging: false
        }).then(canvas => {
          // Convert the resulting canvas to a blob (default is image/png) and
          // make it available as a download
          canvas.toBlob(blob => {
            if (blob) {
              const fileName = data.subtitle ? `${data.subtitle}` : data.title;
              this.saveBlobAs(blob, fileName);
              resultSubject.next();
            } else {
              // Raise the error
              resultSubject.error('The error occured while saving this image.');
            }
            // Remove the new cloned link from the DOM once again
            data.cloneRef.nativeElement.removeChild(cloneLink);
          });
        }, (error) => {
          console.log(error);
          // Raise an error from html2canvas
          resultSubject.error('The error occured while converting this image.');
        });
      }, 10);
      return resultSubject;
    } else {
      return of();
    }
  }

  public saveBlobAs(blob: Blob, fileName: string): void {
    FileSaver.saveAs(blob, `${fileName}.png`);
  }

  // Function to create a simple logo div
  private createLogo(): HTMLDivElement {
    const bigdiv = document.createElement('div');
    bigdiv.style.display = 'flex';
    bigdiv.style.width = '100%';
    bigdiv.style.height = 'auto';
    bigdiv.style.marginTop = '30px';
    bigdiv.style.justifyContent = 'space-between';
    bigdiv.style.alignItems = 'center';

    const img = document.createElement('img');
    img.src = 'assets/img/vm-logo-tm.svg';
    img.height = 30;
    bigdiv.appendChild(img);

    const copyrightTextElement = document.createElement('span');
    copyrightTextElement.style.color = '#54584a';
    const currentYear = new Date().getFullYear();
    copyrightTextElement.textContent = `© Verisk Maplecroft ${currentYear}`;
    bigdiv.appendChild(copyrightTextElement);

    return bigdiv;
  }

  // Function to create the div element
  private createTitle(data: ScorePanelAsImageRequiredData): HTMLDivElement {
    const bigdiv = document.createElement('div');
    bigdiv.style.marginBottom = '41.5px';

    const title = document.createElement('div');
    title.innerHTML = data.title;
    title.style.color = '#002d61';
    title.style.fontSize = '29px';
    title.style.height = '29px';
    bigdiv.appendChild(title);

    if (data.subtitle) {
      const subtitle = document.createElement('div');
      subtitle.innerHTML = data.subtitle;
      subtitle.style.color = '#262626';
      subtitle.style.fontSize = '24px';
      subtitle.style.height = '24px';
      subtitle.style.marginTop = '6px';
      subtitle.style.lineHeight = 'normal';
      bigdiv.appendChild(subtitle);
    }
    return bigdiv;
  }

  private createLegend(data: ScorePanelAsImageRequiredData): HTMLElement {
    const legendNode = data.legendRef.nativeElement.cloneNode(true) as HTMLElement;
    return legendNode;
  }

  private convertSvg(svg: SVGElement): HTMLElement {
    const canvas = document.createElement('canvas');
    const svgSize = svg.getBoundingClientRect();
    canvas.setAttribute('width', svgSize.width + 'px');
    canvas.setAttribute('height', svgSize.height + 'px');

    return canvas;
  }

}
