import { SortCriterion } from '../parameterization';

export enum AnalyseInsightsActionTypes {
  CHANGE_COUNTRY = '[Analyse Insights] Change Country',
  CHANGE_SORT_CRITERIA = '[Analyse Insights] Change Sort Criteria',
  RELOAD_APP = '[Analyse Insights] Reload App',
  RESET = '[Analyse Insights] Reset Insights Content',
  RETRY_LOAD = '[Analyse Insights] Retry loading Insights Content',
  SHOW_DETAIL = '[Analyse Insights] Show Insights Detail',
  SHOW_LISTING = '[Analyse Insights] Show Insights Listing'
}

export type AnalyseInsightsFeature = 'detail' | 'listing';

export interface AnalyseInsightsChangeCountryPayload {
  /**
   * Two character country code for the country whose insights are to be shown,
   * e.g. 'DE', 'CA'.
   */
  countryCode: string;
}

export class AnalyseInsightsChangeCountry {
  public readonly type = AnalyseInsightsActionTypes.CHANGE_COUNTRY;
  constructor(public payload: AnalyseInsightsChangeCountryPayload) { }
}

export class AnalyseInsightsChangeSortCriteria {
  public readonly type = AnalyseInsightsActionTypes.CHANGE_SORT_CRITERIA;
  constructor(public payload: SortCriterion<string>) { }
}

export class AnalyseInsightsReloadApp {
  public readonly type = AnalyseInsightsActionTypes.RELOAD_APP;
  constructor() { }
}

export class AnalyseInsightsReset {
  public readonly type = AnalyseInsightsActionTypes.RESET;
  constructor() { }
}

export class AnalyseInsightsRetryLoad {
  public readonly type = AnalyseInsightsActionTypes.RETRY_LOAD;
  constructor() { }
}

export class AnalyseInsightsShowDetail {
  public readonly type = AnalyseInsightsActionTypes.SHOW_DETAIL;
  constructor(public payload: { id: number }) { }
}

export class AnalyseInsightsShowListing {
  public readonly type = AnalyseInsightsActionTypes.SHOW_LISTING;
  constructor(public payload?: AnalyseInsightsChangeCountryPayload) { }
}

export type AnalyseInsightsActionUnion =
  AnalyseInsightsChangeCountry
  | AnalyseInsightsChangeSortCriteria
  | AnalyseInsightsReloadApp
  | AnalyseInsightsReset
  | AnalyseInsightsRetryLoad
  | AnalyseInsightsShowDetail
  | AnalyseInsightsShowListing;
