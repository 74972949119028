import { ChangeDetectorRef } from '@angular/core';
import { CountryCode } from '@grid-ui/common';
import { Map as OlMap, Tile, VectorTile, View } from 'ol';
import Feature, { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import MVT from 'ol/format/MVT';
import { Geometry, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorTileLayer from 'ol/layer/VectorTile';
import TileLayer from 'ol/layer/WebGLTile.js';
import { fromLonLat, useGeographic } from 'ol/proj';
import { Cluster } from 'ol/source';
import GeoTIFFSource from 'ol/source/GeoTIFF';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import { Fill, Style } from 'ol/style';
import { RiskColorScale } from './color/risk-color-scale';
import { StyleColorPalette } from './color/style-color-palette';
import { MapEventHandler } from './events/map-event-handler';
import { ScoreStringAndColor } from './models';
import { OSMMapStyle } from './styles/osm-map-style';
import { CLUSTER_DIAMETER, SitesAndScoresLayerStyle } from './styles/sites-and-scores-style';

export interface RiskMapProps {
  /** The colour palette for vector features. */
  readonly colorPalette: StyleColorPalette;

  /** The HTML Element for the map container. */
  readonly target: HTMLElement;

  /**
   * The vector feature URL.
   *
   * @example
   *
   * await this.mapService.urlForVector('grid-analyse')
   */
  readonly vectorFeatureUrl: string;

  /**
   * Reference to the injected document
   */
  readonly document: Document;

  /**
   * Reference to the injected ChangeDetectorRef
   */
  readonly changeDetectorRef: ChangeDetectorRef;
}

/**
 * An interface for use with addSitesWithScores().
 */
export interface SitesWithScores {
  readonly siteId: string;
  readonly siteName: string;
  readonly latitude: number;
  readonly longitude: number;
  /**
   * The risk score as a string.
   *
   * @see ceilRiskScoreToString()
   */
  readonly score: string;
  /**
   * The RGB risk colour string.
   *
   * @example 'rgb(123, 123, 123)'
   */
  readonly color: string;
  /**
   * The RGB text colour which can safely be displayed
   * on top of the risk colour.
   */
  readonly textColor: string;
}

export type CountryCodeScores = Map<CountryCode, ScoreStringAndColor>;

/**
 * A base risk map.
 *
 * Only contains the bottom OSM and top OSM layers.
 */
export class GRiDRiskMap {
  public readonly map: OlMap;
  public readonly osmBaseLayerStyle: OSMMapStyle;
  public readonly osmWaterLayerStyle: OSMMapStyle;
  public readonly osmAdminBoundariesLayerStyle: OSMMapStyle;
  public readonly osmLabelsLayerStyle: OSMMapStyle;
  public readonly osmVectorSource: VectorTileSource;
  protected readonly sitesAndScoresLayerStyle: SitesAndScoresLayerStyle;

  private colorPalette: StyleColorPalette;

  /**
   * All map events added by `addMapEventHandler()`.
   * This array is used to track events for tear down.
   */
  private readonly mapEventHandlers = new Set<MapEventHandler>();

  constructor(private readonly props: RiskMapProps) {
    useGeographic(); // Ensure points go to the right place.

    this.map = new OlMap({
      target: props.target,
      controls: [],
      view: new View({
        center: fromLonLat([0, 0]),
        minZoom: 3,
        zoom: 3,
        maxZoom: 20,
        // Set explicit extent to prevent panning outside the world.
        extent: [-190, -85, 190, 85],
      }),
    });

    // Set the class color palette from the default provided by props
    this.colorPalette = props.colorPalette;

    // Define safe defaults for the OSM base layer style.
    this.osmBaseLayerStyle = new OSMMapStyle(this.colorPalette, {
      adminBoundaries: false,
      streets: true,
      greenery: false,
      sand: true,
      ocean: false,
      waterBodies: false,
      labels: false,
      streetLabels: true,
      buildings: true,
      buildingLabels: true,
      structures: true,
    });

    this.osmWaterLayerStyle = new OSMMapStyle(this.colorPalette, {
      adminBoundaries: false,
      streets: false,
      greenery: false,
      sand: false,
      ocean: true, // Ocean on top by default to clip neatly around coastlines
      waterBodies: true,
      labels: false,
      streetLabels: false,
      buildings: false,
      buildingLabels: false,
      structures: false,
    });

    this.osmAdminBoundariesLayerStyle = new OSMMapStyle(this.colorPalette, {
      adminBoundaries: true,
      streets: false,
      greenery: false,
      sand: false,
      ocean: false,
      waterBodies: false,
      labels: false,
      streetLabels: false,
      buildings: false,
      buildingLabels: false,
      structures: false,
    });

    // And again for the OSM top layer.
    this.osmLabelsLayerStyle = new OSMMapStyle(this.colorPalette, {
      adminBoundaries: false,
      streets: false,
      greenery: false,
      sand: false,
      ocean: false,
      waterBodies: false,
      labels: true,
      streetLabels: false,
      buildings: false,
      buildingLabels: false,
      structures: false,
    });

    // And again for the site scores layer.
    this.sitesAndScoresLayerStyle = new SitesAndScoresLayerStyle(
      this.colorPalette,
      this.props.document
    );

    // Add all OSM layers.  Data layers will be added between using zIndex.
    this.osmVectorSource = this.getVectorSource(props.vectorFeatureUrl);

    const osmBaseLayer = this.getOSMVectorBaseLayer(
      this.osmVectorSource,
      this.colorPalette,
      this.osmBaseLayerStyle
    );
    const osmWaterLayer = this.getOSMVectorWaterLayer(
      this.osmVectorSource,
      this.osmWaterLayerStyle
    );
    const osmAdminBoundaryLayer = this.getOSMVectorAdminBoundaryLayer(
      this.osmVectorSource,
      this.osmAdminBoundariesLayerStyle
    );
    const osmLabelsLayer = this.getOSMVectorLabelsLayer(
      this.osmVectorSource,
      this.osmLabelsLayerStyle
    );

    // Base layer is for buildings, roads, etc. Will sometimes have water depending on setLayerVisibility()
    this.map.addLayer(osmBaseLayer);
    // Water includes the ocean and water bodies unless toggled with setLayerVisibility()
    this.map.addLayer(osmWaterLayer);
    // Admin boundaries is for national and subnational boundaries
    this.map.addLayer(osmAdminBoundaryLayer);
    // Top layer is for labels
    this.map.addLayer(osmLabelsLayer);

    // Display the map.
    this.map.updateSize();

    this.props.document.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
  }

  /**
   * Disposes the map and any event listeners. This should be called ngOnDestroy from any consuming components to prevent a memory leak.
   */
  public dispose(): void {
    this.map.dispose();

    this.props.document.removeEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));

    this.removeMapEventHandlers();
  }

  public get isFullScreen(): boolean {
    return !!this.props.document.fullscreenElement;
  }

  /**
   * Handles a full screen change event from the document
   */
  private handleFullScreenChange(): void {
    this.map.updateSize();

    this.props.changeDetectorRef.markForCheck();
  }

  /**
   * Gets the OSM vector base layer (streets, greenery, sand, buildings, etc.)
   * @param url The relative or absolute URL to the MVT-compliance resource.
   * @param colorPalette The colour palette to use.
   * @param osmStyle The OSMMapStyle object.
   */
  private getOSMVectorBaseLayer(
    source: VectorTileSource,
    colorPalette: StyleColorPalette,
    osmStyle: OSMMapStyle
  ): VectorTileLayer<any> {
    return new VectorTileLayer({
      zIndex: 0,
      className: 'osm-base',
      declutter: true,
      background: colorPalette.backgroundFill.toString(),
      source: source,
      style: (f: FeatureLike, r: number) => osmStyle.getStyleFunction(f, r),
    });
  }

  /**
   * Gets the OSM vector top layer (admin boundaries, labels, etc.)
   * @param url The relative or absolute URL to the MVT-compliance resource.
   * @param osmStyle The OSMMapStyle object.
   */
  private getOSMVectorWaterLayer(
    source: VectorTileSource,
    osmStyle: OSMMapStyle
  ): VectorTileLayer<any> {
    return new VectorTileLayer({
      zIndex: 10,
      className: 'osm-water',
      source: source,
      style: (f: FeatureLike, r: number) => osmStyle.getStyleFunction(f, r),
    });
  }

  /**
   * Gets a vector tile source as MVT with a maximum zoom of 14.
   * @param vectorUrl The absolute or relative URL to the MVT-compliant resource.
   * @returns The VectorTileSource.
   */
  protected getVectorSource(vectorUrl: string, layers?: string[]): VectorTileSource {
    return new VectorTileSource({
      maxZoom: 14,
      attributions:
        '<a href="https://www.openstreetmap.org">&copy; OpenStreetMap</a> contributors',
      format: new MVT({
        layers: layers,
      }),
      url: vectorUrl,
      tileLoadFunction: (tile: Tile, url: string) => {
        const vectorTile = tile as VectorTile;
        vectorTile.setLoader(function (extent, _resolution, projection) {
          fetch(url, { credentials: 'include' }).then(function (response) {
            response.arrayBuffer().then(function (data) {
              const format = vectorTile.getFormat(); // ol/format/MVT configured as source format
              const features = format.readFeatures(data, {
                extent: extent,
                featureProjection: projection,
              });
              vectorTile.setFeatures(features);
            });
          });
        });
      },
    });
  }

  /**
   * Gets an OpenLayers GeoTIFF source for a given URL.
   *
   * The source must be a Cloud Optimised GeoTIFF.
   *
   * @param rasterUrl The URL to the .tif or .tiff.
   * @returns An OpenLayers GeoTIFFSource
   */
  private getRasterSource(rasterUrl: string): GeoTIFFSource {
    return new GeoTIFFSource({
      sourceOptions: {
        credentials: 'include',
      },
      interpolate: false, // The data is scientific, it should not be altered
      normalize: false,
      sources: [
        {
          url: rasterUrl,
          nodata: 0, // The GeoTIFFProcessor in the Maps backend scales data from 1-255 with nodata as 0.
        },
      ],
    });
  }

  /**
   * Waits for all tiles to be rendered.
   *
   * May need some timeout handling.
   *
   * @returns void
   */
  public waitForMapRenderComplete(
    gridRiskMap: GRiDRiskMap
  ): Promise<GRiDRiskMap> {
    return new Promise((resolve, _) => {
      this.map.on('rendercomplete', () =>
        resolve(gridRiskMap)
      );
    });
  }

  /**
   * Sets the color palette for all supported resources.
   * @param colorPalette The color palette.
   */
  public setColorPalette(colorPalette: StyleColorPalette): void {
    this.colorPalette = colorPalette;
    this.osmBaseLayerStyle.setColorPalette(colorPalette);
    this.osmWaterLayerStyle.setColorPalette(colorPalette);
    this.osmLabelsLayerStyle.setColorPalette(colorPalette);
    this.osmAdminBoundariesLayerStyle.setColorPalette(colorPalette);
    this.sitesAndScoresLayerStyle.setColorPalette(colorPalette);

    this.map
      .getAllLayers()
      .filter((layer) => layer.getClassName() === 'osm-base')
      .forEach((layer) =>
        layer.setBackground(colorPalette.backgroundFill.toString())
      );

    this.map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        layer.changed();
        return layer;
      });
  }

  /**
   * Refreshes all layers.
   *
   * Useful if you've changed something like the OSM layer visibility.
   */
  public refreshLayers(): void {
    this.map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        layer.changed();
        return layer;
      });
  }

  /**
   * Adds a map event handler. See map-events.ts.
   *
   * @param mapEventHandler The instantiated map event handler.
   */
  public addMapEventHandler(mapEventHandler: MapEventHandler): void {
    mapEventHandler.activate(this.map);

    this.mapEventHandlers.add(mapEventHandler);
  }

  /**
   * Removes all map event handlers added by `addMapEventHandler()`.
   */
  public removeMapEventHandlers(): void {
    this.mapEventHandlers.forEach((mapEventHandler) => mapEventHandler.dispose());

    this.mapEventHandlers.clear();
  }

  /**
   * Zooms the map to a bounding extent.
   * @param coordinates An array of four coordinates.
   */
  public zoomToBoundingExtent(coordinates: Coordinate[]): void {
    this.map.getView().fit(boundingExtent(coordinates), {
      duration: 200,
      padding: [50, 50, 50, 50],
    });
  }

  /**
   * Removes all data layers.
   *
   * Written as a hack since we couldn't figure out why
   * the map component was being called multiple times,
   * sometimes with old @Input data.
   */
  public removeDataLayers(): void {
    const classNames = [
      'country-mask-layer',
      'raster-layer',
      'sites-with-scores-layer',
      'country-risk-layer',
    ];
    this.map
      .getLayers()
      .getArray()
      .filter((layer) => classNames.includes(layer.getClassName()))
      .forEach((layer) => this.map.removeLayer(layer));
  }

  /**
   * Removes the mask layer.
   */
  public removeMaskLayer(): void {
    const classNames = ['country-mask-layer'];
    this.map
      .getLayers()
      .getArray()
      .filter((layer) => classNames.includes(layer.getClassName()))
      .forEach((layer) => this.map.removeLayer(layer));
  }

  /**
   * Adds a raster data layer.
   * @param rasterUrl The URL to the GeoTIFF raster.
   * @param zIndex A number between 1-5
   * @param extent An optional extent. Useful when combined with masking for optimised loading
   */
  public addRasterDataLayer(
    rasterUrl: string,
    opacity: number,
    zIndex: 1 | 2 | 3 | 4 | 5,
    colorScale: RiskColorScale,
    extent?: Coordinate[]
  ): void {
    this.map.addLayer(
      new TileLayer({
        zIndex: zIndex,
        className: 'raster-layer',
        source: this.getRasterSource(rasterUrl),
        opacity: opacity,
        style: colorScale.getRasterWebGLStyle(),
        ...(extent && {
          extent: boundingExtent(extent),
        }),
      })
    );
  }

  /**
   * Adds a country risk data layer to the map.
   *
   * Each country will be coloured based on its iso_alpha2 2-letter country code.
   *
   * @param countryCodeScores A dictionary of country codes and their scores.
   * @param opacity The layer opacity.
   * @param zIndex The zIndex between 1-5.
   */
  public addCountryRiskDataLayer(
    countryCodeScores: CountryCodeScores,
    opacity: number,
    zIndex: 1 | 2 | 3 | 4 | 5
  ): void {
    this.map.addLayer(
      new VectorTileLayer({
        opacity: opacity,
        zIndex: zIndex,
        className: 'country-risk-layer',
        source: this.osmVectorSource,
        style: (feature: FeatureLike) => {
          if (feature.get('layer') === 'admin_boundaries') {
            const countryCode = feature.get('iso_alpha2');

            if (countryCodeScores.has(countryCode)) {
              return new Style({
                // TODO is style caching faster/less mem?
                fill: new Fill({ color: countryCodeScores.get(countryCode)?.color }),
              });
            }
          }

          return;
        },
      })
    );
  }

  /**
   * Gets the OSM vector top layer (admin boundaries, labels, etc.)
   * @param url The relative or absolute URL to the MVT-compliance resource.
   * @param osmStyle The OSMMapStyle object.
   */
  private getOSMVectorAdminBoundaryLayer(
    source: VectorTileSource,
    osmStyle: OSMMapStyle
  ): VectorTileLayer<any> {
    return new VectorTileLayer({
      zIndex: 11,
      className: 'osm-admin-boundaries',
      source: source,
      renderMode: 'vector',
      style: (f: FeatureLike, r: number) => osmStyle.getStyleFunction(f, r),
    });
  }

  /**
   * Gets the OSM labels layer.
   * @param url The relative or absolute URL to the MVT-compliance resource.
   * @param osmStyle The OSMMapStyle object.
   */
  private getOSMVectorLabelsLayer(
    source: VectorTileSource,
    osmStyle: OSMMapStyle
  ): VectorTileLayer<any> {
    return new VectorTileLayer({
      zIndex: 12,
      className: 'osm-labels',
      source: source,
      declutter: true,
      style: (f: FeatureLike, r: number) => osmStyle.getStyleFunction(f, r),
    });
  }

  /**
   * Adds a masking data layer to country codes outside of countryCodes.
   *
   * Uses some canvas tweaking to perform sharper masking.
   *
   * @param countryCodes An array of ISO Alpha-2 country codes that are visible.
   * @param zIndex The zIndex.
   */
  public addCountryMaskDataLayerV2(
    countryCodes: string[],
    zIndex: 1 | 2 | 3 | 4 | 5
  ): void {
    const maskLayer = new VectorTileLayer({
      zIndex: zIndex,
      className: 'country-mask-layer',
      source: this.osmVectorSource,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      style: (feature: FeatureLike, resolution: number) => {
        if (feature.get('layer') === 'admin_boundaries') {
          const countryCode = feature.get('iso_alpha2');
          const adminLevel = feature.get('admin_level');
          /**
           * When country codes are provided to this method, any matching polygons
           * in our admin_boundaries layer will be filled. The fill colour is actually
           * arbitrary because we're doing some HTML Canvas XOR stuff to perform masking.
           * See below for the XOR logic.
           *
           * Additionally, we're only doing this for admin_level 0 because it's visible
           * at any zoom level.  Other admin levels become available as you zoom in, so
           * and there's no need to use those when we've already covered the countries
           * from admin_level 0.
           */
          if (countryCodes.includes(countryCode) && adminLevel === 0) {
            return new Style({
              fill: new Fill({ color: this.colorPalette.maskFill }),
            });
          } else {
            return;
          }
        } else {
          return;
        }
      },
    });

    /**
     * Smart masking will fill the entire canvas with
     * white and using XOR will ensure new shapes added
     * will be subtracted from the white.
     *
     * Combined with CSS opacity on the mask className
     * will give a semi-transparent mask without any
     * bleed.
     */
    maskLayer.on('postrender', (event) => {
      const ctx = event.context as CanvasRenderingContext2D;
      const canvas = ctx.canvas;

      ctx.globalCompositeOperation = 'xor';
      ctx.fillStyle = this.colorPalette.maskFill;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';
    });

    this.map.addLayer(maskLayer);
  }

  /**
   * Adds site markers with their scores to the map.
   * @param map The OpenLayers Map.
   * @param sitesWithScores An array of dictionaries.
   */
  public addSitesWithScores(sitesWithScores: SitesWithScores[]): void {
    const features: Feature<Geometry>[] = sitesWithScores.map(
      ({ longitude, latitude, siteId, siteName, score, color, textColor }) =>
        new Feature({
          geometry: new Point([longitude, latitude]),
          siteId,
          siteName,
          score,
          color,
          textColor
        })
    );

    const source = new VectorSource({
      features,
    });

    const cluster = new Cluster({
      source,
      minDistance: CLUSTER_DIAMETER,
      distance: CLUSTER_DIAMETER,
    });

    const layer = new VectorLayer({
      zIndex: 12,
      source: cluster,
      // className ensures we show scores above text.
      className: 'sites-with-scores-layer',
      style: (feature: FeatureLike) =>
        this.sitesAndScoresLayerStyle.getStyleFunction(feature),
    });

    this.map.addLayer(layer);
  }

  /**
   * Sets the opacity of data layers.
   *
   * Data layers are identified using their classNames
   * provided in this method. Update the array as needed.
   * @param opacity A floating point number between 0-1.
   */
  public setDataLayerOpacity(opacity: number): void {
    const classNames = ['raster-layer', 'country-risk-layer'];
    this.map
      .getLayers()
      .getArray()
      .filter((layer) => classNames.includes(layer.getClassName()))
      .forEach((layer) => layer.setOpacity(opacity));
  }

  /**
   * Toggles full screen mode for the map
   */
  public toggleFullScreen(): void {
    if (this.isFullScreen) {
      this.leaveFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  /**
   * Enters full screen.
   *
   * This function was written because we wanted custom UI elements.
   *
   * map.updateSize is called to prevent flickering,
   * taken from OpenLayers source code.
   *
   * @see https://github.com/openlayers/openlayers/blob/main/src/ol/control/FullScreen.js
   */
  public enterFullScreen(): void {
    this.map
      .getTargetElement()
      .requestFullscreen()
      .catch((err) => console.error(`Failed to enter full screen: ${err}`));
  }

  /**
   * Leaves full screen.
   *
   * This function was written because we wanted custom UI elements.
   */
  public leaveFullScreen(): void {
    this.props.document
      .exitFullscreen()
      .catch((err) => console.error(`Failed to leave full screen: ${err}`));
  }

  /**
   * Zooms the map view in.
   *
   * This function was written because we wanted custom UI elements.
   *
   * @see https://github.com/openlayers/openlayers/blob/main/src/ol/control/Zoom.js
   */
  public zoomIn(): void  {
    if (this.zoom) {
      this.map.getView().animate({ zoom: Math.round(this.zoom) + 1, duration: 250 });
    }
  }

  /**
   * Zooms the map view out.
   *
   * This function was written because we wanted custom UI elements.
   */
  public zoomOut(): void {
    if (this.zoom) {
      this.map.getView().animate({ zoom: Math.round(this.zoom) - 1, duration: 250 });
    }
  }

  public get zoom(): number | undefined {
    return this.map.getView().getZoom();
  }
}
