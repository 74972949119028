import { Style as WebGLTileStyle } from 'ol/layer/WebGLTile';

import { RiskColorPalette } from './risk-color-palette';

export abstract class RiskColorScale {
  constructor(
    /**
     * Our risk colours.
     *
     * TODO - Rename to color palette or something
     */
    protected riskColors: RiskColorPalette
  ) {}

  /**
   * Returns an CSS RGB string for an array of 3 number values representing RGB.
   * @param color E.g. [123, 123, 123]
   * @returns A CSS RGB value e.g. rgb(123, 123, 123)
   */
  protected colorArrayToRGB(color: number[]): string {
    return `rgb(${color.map(Math.round).join(',')})`;
  }

  /**
   * Gets the RGB value for a score.
   * @param colors The IRiskColorScale.
   * @param score The score between 0-10 (floating point).
   */
  abstract getRGBStringForScore(score: number): string;

  /**
   * Precomputes country colours based on their risk score.
   *
   * @returns A dictionary of country codes and their respective risk colour.
   */
  public getRGBStringsForScores(scores: { [key: string]: number }): {
    [key: string]: string;
  } {
    const keyColors: { [key: string]: string } = {};

    Object.entries(scores).forEach(([key, score]) => {
      keyColors[key] = this.getRGBStringForScore(score);
    });
    return keyColors;
  }

  /**
   * Gets a style for a WebGL Tile Layer.
   *
   * We're using this to dynamically colour rasters on-the-fly.
   * The source raster must be 8-bit and have valid data from
   * 1-255. 0 is expected to be the nodata value.
   *
   * @param riskColorScale The risk color to use.
   * @returns The WebGLTileStyle.
   */
  public getRasterWebGLStyle(): WebGLTileStyle {
    // This fix requires normalise: false in the OL source
    // and expects real data to be in 1-255
    const colorStops = this.riskColors.map((color, index) => {
      // Calculate the scale value to fit within the 1-255 range
      const scaleValue =
        1 + Math.round(index * (254 / (this.riskColors.length - 1)));
      // Convert RGB to RGBA with an alpha value of 1
      const rgbaColor = [...color, 1];
      return [scaleValue, rgbaColor];
    });

    // WebGL style to colour a GeoTIFF on-the-fly.
    // We're using our common risk colour scale.
    return {
      color: [
        'case',
        ['==', ['band', 2], 0], // Band 2 is the alpha channel.
        'rgba(0, 0, 0, 0)', // Set transparent color for 'no data' areas
        ['interpolate', ['linear'], ['band', 1], ...colorStops.flat()],
      ],
    };
  }

  /**
   * Setter for risk colors.
   */
  public setRiskColors(riskColors: RiskColorPalette): void {
    this.riskColors = riskColors;
  }

  /**
   * Getter for risk colors.
   */
  public getRiskColors(): RiskColorPalette {
    return this.riskColors;
  }
}

/**
 * A sequential unclassed (linear) risk color scale.
 */
export class SequentialUnclassedRiskColorScale extends RiskColorScale {
  public getRGBStringForScore(score: number): string {
    const segmentCount = this.riskColors.length - 1;
    const segmentIndex = Math.min(
      Math.floor((score * segmentCount) / 10),
      segmentCount - 1
    );
    const segmentRatio = (score / 10) * segmentCount - segmentIndex;

    return this.colorArrayToRGB(
      this.riskColors[segmentIndex].map((minVal, index) => {
        const maxVal = this.riskColors[segmentIndex + 1][index];
        return minVal + segmentRatio * (maxVal - minVal);
      })
    );
  }
}

/**
 * A sequential classed risk color scale.
 *
 * Colors will be classified based on their score
 * and the array length of provided risk colors.
 *
 * For example, if 4 RGB colors are supplied to
 * the constructor, the classes will start from
 * 0-2.49 and so on, until 10 is reached.
 */
export class SequentialClassedRiskColorScale extends RiskColorScale {
  public getRGBStringForScore(score: number): string {
    const range = 10; // Assuming scores range from 0 to 10
    const classCount = this.riskColors.length;
    const classSize = range / classCount;
    const classIndex = Math.min(Math.floor(score / classSize), classCount - 1);

    return this.colorArrayToRGB(this.riskColors[classIndex]);
  }
}
